import { concatMsg } from '@/utils/util';
let itm = 'router.txt.fitness';

const fitnessBoardListRouter = {
  path: "/boardlist",
  name: "FitnessBoardList",
  meta: { title: concatMsg(['board.txt.mgmt',itm]), requiresAuth: true, },
  component: () => import(`@/views/board/FitnessBoardList`),
};

const fitnessBoardModifyRouter = {
  path: "/boardmodify",
  name: "FitnessBoardModify",  
  meta: {
    title: route => concatMsg([route.query.boardcd ? 'router.board.modify' : 'router.board.reg', itm]),
    requiresAuth: true
  },
  component: () => import(`@/views/board/FitnessBoardModify`),
};

export { fitnessBoardListRouter, fitnessBoardModifyRouter };
