import store from "../store";
import { ref } from "vue";
import { formValidate } from "@/utils/util";
import dayjs from "dayjs";
import { getMsg, isEmpty } from '@/utils/util';

const validateModal = ref(null);
const validateMessage = ref(null);
const callbackModal = ref(null);
const callbackMessage = ref(null);

const loginValidate = () => {
  const isLogin = store.getters["userStore/getJWT"];
  if (!isLogin) {
    callbackModal.value.show();
    showMessage(getMsg('common.check.fileext'));
    callbackMessage.value = getMsg('common.need.login');
    return false;
  }
  return true;
};

const fileValidate = (id, file) => {
  const fileType = id.indexOf('img') > -1 ? 'img' : 'file';
  const fileExt = fileType == 'img' ? /(\.|\/)(png|jpeg|gif|jpg|bmp)$/i : /(\.|\/)(png|jpeg|gif|jpg|bmp|doc|xls|ppt|hwp|pdf|mp4|avi|mkv|mov)$/i;  
  if (!fileExt.test(file.type) && !fileExt.test(file.name)) {
    showMessage(getMsg('common.check.fileext'));
    return false;
  }
  return true;
}

const showMessage = (message, zIndex = 3000) => {
  validateModal.value.setZindex(zIndex);
  validateMessage.value = message;
  validateModal.value.show();
};

const hideMessage = () => {
  validateModal.value.hide();
};

const isShowValidateModal = () => {  
  return validateModal.value.isShow();
}

const replaceMessage = (msg) => {
  validateModal.value.setMsg(msg);
}

const callbackShowMessage = (message) => {
  callbackModal.value.show();
  callbackMessage.value = message;
};

export {  
  validateModal,
  validateMessage,  
  callbackModal,
  callbackMessage,  
  replaceMessage,
  isShowValidateModal,
  fileValidate,
  loginValidate,
  showMessage,
  hideMessage,
  callbackShowMessage,  
};
