import { isProxy, toRaw } from 'vue';
import message from '@/utils/messages';
import moment from 'moment-timezone';
import 'moment/locale/ko';
// import { sortBy, isEmpty as iet } from 'lodash/core'; // only collection
import * as cloneDeep from 'lodash/cloneDeep';
import { Comment, Fragment, Text } from 'vue';
moment.locale("ko-kr");
const imageFileList = ['jpg', 'jpeg', 'bmp', 'png'];
let isDev = process.env.NODE_ENV == 'development' ? true : false;

export const isEmpty = (value) => {
  if (typeof value == 'number') value = value.toString()
  if (value == "" || value == null || value == undefined || (value != null && typeof value == "object" && !Object.keys(value).length)) {
    return true
  } else {
    return false
  }
};

export function removeSpace(value) {
  return value.toString().replace(/\s+/g, "");
}

// 문자열 바이트 계산
export function getByte(str) {
  let byte = 0;
  for (let i = 0; i < str.length; ++i) {
    str.charCodeAt(i) > 127 ? (byte += 3) : byte++;
  }
  return byte;
}

export function fillZero(width, str) {
  return str.length >= width ? str : new Array(width - str.length + 1).join("0") + str;
}

export const comma = (value) => {
  value = String(value).replace(/[^\d-]/g, "");

  let isNegative = value.startsWith("-");
  let numberPart = isNegative ? value.substring(1) : value;

  numberPart = numberPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return isNegative ? "-" + numberPart : numberPart;
};

export const uncomma = (value) => {
  return String(value).replace(/[^\d]+/g, "");
};

export const inputNumberFormat = (obj) => {
  return comma(uncomma(obj));
};

export function formValidate(val, type, length) {
  const phoneReg = /^01(?:0|1|[6-9])([0-9]{3}|[0-9]{4})([0-9]{4})+$/;
  const emailReg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
  const passwordReg = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^*+=-]).{8,15}$/;
  if (!val) {
    return false;
  }
  else if(Array.isArray(val) && isProxy(val)){
    const rawData = toRaw(val);
    if(rawData.length == 0) return false;//데이터가 없으면 false처리
    else {//파라미터로 받은 length를 체크
      let emptySomeCount = 0;
      rawData.map(item => {
        const emptySome = Object.values(item).some(value => value === "" || value === null || value === undefined)
        if(emptySome) emptySomeCount++;
      });
      return emptySomeCount ? false : true;
    }
  } 
  else {
    if (type === "require") {
      return !!removeSpace(val);
    }
    if (type === "phone") {
      return phoneReg.test(val);
    }
    if (type === "email") {
      return emailReg.test(val);
    }
    if (type === "password") {
      return passwordReg.test(val);
    }
  }
}

export const splitHash = (item) => {
  if (!item) return;
  const arr = item.split("#");
  const newArr = [];
  for (let i of arr) {
    if (i) {
      newArr.push(`#${i}`);
    }
  }
  return newArr;
};

export const getLastMonth = () => {
  const date = new Date();
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastMonth = new Date(firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 1));
  const LASTMONTH = lastMonth.getMonth() + 1;

  return LASTMONTH;
};

export const aWeekAgo = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const RESULT = new Date(year, month, day - 7);

  const getWeekNum = (result) => {
    let date = new Date();
    if (result) {
      date = new Date(result);
    }
    return Math.ceil(date.getDate() / 7);
  };

  const aWeekAgoNum = getWeekNum(RESULT);
  const aWeekAgoMonth = RESULT.getMonth() + 1;

  return { aWeekAgoNum, aWeekAgoMonth };
};

// export const delay = (function () {
//   var timer = 0;
//   return function (callback, ms) {
//       clearTimeout(timer);
//       timer = setTimeout(callback, ms);
//   };
// })();

export const delay = (() => {
  const timers = new Map(); // 콜백별로 타이머를 관리
  return function (callback, ms) {
    if (timers.has(callback)) {
      clearTimeout(timers.get(callback));
    }
    const timer = setTimeout(() => {
      callback();
      timers.delete(callback);
    }, ms);
    timers.set(callback, timer);
  };
})();

export const getMsg = (id, pattern) => {
  try {
    let m = message[id];
    if (!isEmpty(pattern)) {
      Object.keys(pattern).forEach(function (e, i, a) {
        m = m.replace(new RegExp(e, 'g'), pattern[e]);
      })
    }
    return m;
  } catch(e) {
    logs(["[util][getMsg]", e]);
    return '';
  }
}

export const formatDate = (data = new Date(), fmt = 'YYYY') => {
  return moment(data).format(fmt)
}

export const reduceTxt = (data) => {
  return data.length > 20 ? data.substring(1, 15).concat('...') : data;
}

export const getFilePath = (data, type = '') => {
  if (Array.isArray(data)) data = data[0];
  return !isEmpty(data) && !isEmpty(data.FILE_KEY) ? '/api/file/'.concat(data.FILE_KEY, type) : '';
}

export const bizNoFormatter = (bizNo) => {  
  bizNo = bizNo.replace(/[^0-9]/g,"");
  let rtn = "", r = "", l = "", x = "", q = "";
  let num = Number(bizNo.length);
  if (num < 4) {
    rtn = bizNo;
  } else if (num <= 5) {
    l = num - 3;
    x = "(\\d{3})(\\d{"+l+"})";
    r = '$1-$2';
  } else if (num > 10) {
     q = num - 10;
     x = "(\\d{3})(\\d{2})(\\d{5})(\\d{" + q + "})";
     r = '$1-$2-$3-$4';
  } else {
    l = num - 5;
    x = "(\\d{3})(\\d{2})(\\d{" + l + "})";
    r = '$1-$2-$3';
  }
  if (x) rtn = bizNo.replace(new RegExp(x), r);
  return rtn;
}

export const vNodeIsEmpty = (vnodes) => {
  return vnodes.every(node => {
    if (node.type === Comment) return true;
    if (node.type === Text && !node.children.trim()) return true;
    if (node.type === Fragment && vNodeIsEmpty(node.children)) return true;
    return false;
  })
}

export const deepClone = (data) => {
  return cloneDeep(data);
}

export const concatMsg = (data, j = ' '.concat(getMsg('router.txt.hyphen'), ' ')) => {
  let rtn = [];
  for (let m of data) {
    rtn.push(getMsg(m));
  }
  return rtn.join(j);
}

export const openUrl = (url) => {
  if (!isEmpty(url) && url.indexOf('https://') < 0 && url.indexOf('http://') < 0 && url.indexOf('//') < 0) url = '//'.concat(url);  
  return url;
}

export const reduceFileName = (filename, reducenum = 20) => {
  let point = filename.lastIndexOf('.');
  let name = filename;
  let ext = '';
  if (point > -1) {
    name = filename.substring(0, point);
    ext = filename.substring(point, filename.length);
  }
  if (name.length > reducenum) name = name.substring(0, reducenum).concat('...');  
  return name.concat(ext);
}

export const isValidImage = (ext) => {
	var rtn = false
	ext = ext.toLowerCase();
	for (var i in imageFileList) {
		if (ext.indexOf(imageFileList[i]) > -1) {
			rtn = true
			break
		}
	}
	return rtn
}

export const groupBy = (xs, f) => {
  return xs.reduce(function (r, v, i, a, k) {
    var k = f(v);
    if (!r[k]) r[k] = [];
    return r[k].push(v), r
  }, {})
}

export const lpad = (str, padLen, padStr) => {
  str += "";
  padStr += "";
  while (str.length < padLen)
    str = padStr + str;
    str = str.length >= padLen ? str.substring(0, padLen) : str;
  return str;  
}

export const isEmptyObject = (obj) => {
  return Object.values(obj).every(value => !value);
};

export const timer = (ms) => { 
	return new Promise(function(resolve, reject) {
		var time = setTimeout(function () {			
			clearTimeout(time);
			resolve();
		}, ms)
	}); 
}
export const logs = (arr, isForce) => {
  if(isDev || isForce) console.log(...arr);
}

export const getNestedValue = (object, path) => {
	return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), object);
}

export const stringToBoolean = (str) => {
	let s = str.toLowerCase();
	let rtn = str;
	if (s === "true") {
		rtn = true;
	} else if (s === "false") {
		rtn = false;
	}	
	return rtn;
};