<template>
  <router-view :key="$route.fullPath" />
  <token ref="refToken"/>
</template>
<script setup>
import { watch } from "@vue/runtime-core";
import Token from '@/components/common/Token';
import { ref } from "@vue/reactivity";
import { provide } from 'vue';
import { onMounted } from '@vue/runtime-core';
const isActiveTimer = ref(false);
const refToken = ref(null);
const setActiveTimer = (bl) => {
  isActiveTimer.value = bl;
}
watch(() => isActiveTimer.value, (newValue, oldValue) => {
  if (!oldValue && newValue) refToken.value.tokenExpiry();
});
provide("isActiveTimer", isActiveTimer);
provide("setActiveTimer", setActiveTimer);

onMounted(() => {
  if (!isActiveTimer.value) setActiveTimer(true);  
});
</script>