import { concatMsg } from '@/utils/util';
let itm = 'router.txt.fitness';

const indexRouter = {
  path: "/",
  name: "Home",
  meta: { title: concatMsg(['board.txt.mgmt',itm]), requiresAuth: true, },
  component: () => import(`@/views/board/FitnessBoardList`),
};

const systemErrorRouter = {
  path: "/systemError",
  name: "SystemError",  
  meta: { title: concatMsg(['router.txt.error',itm]) },
  component: () => import(`@/views/error/500`),  
};

const notFoundRouter ={
  path: "/:pathMatch(.*)*",
  name: "NotFound",
  meta: { title: concatMsg(['router.txt.error',itm]) },
  component: () => import(`@/views/error/404`),
};

export { indexRouter, systemErrorRouter, notFoundRouter };
