<template>
	<teleport to="#destination">
		<modal-warning ref="warningModal">
			<template v-slot:modalContent><p class="mb-0 font-size-16 fw-medium text-black text-center" v-dompurify-html="warningMessage"></p></template>			
		</modal-warning>
	</teleport>
</template>

<script setup>
import { onMounted, onUnmounted } from '@vue/runtime-core';
import { ref, toRefs } from "@vue/reactivity";
import ModalWarning from "@/components/modal/ModalWarning";
import { getMsg, isEmpty, logs } from '@/utils/util';
import loginServies from "@/services/user/login-servies";
import moment from 'moment-timezone';
import { inject } from 'vue';

const { getAutoLogout, getRemainTime } = loginServies();
const warningModal = ref(null);
const warningMessage = ref(null);
const isActiveTimer = inject('isActiveTimer');
const setActiveTimer = inject('setActiveTimer');
let timeRemaining = ref(0);
let remainTimer = null;
let logoutTimer = null;
const isDev = process.env.NODE_ENV == 'development' ? true : false;

onUnmounted(() => {});
onMounted(() => {});

const tokenExpiry = () => {
    let pathName = window.location.pathname;
    if (pathName.indexOf('/login') > -1) {
        logs(['[TOKEN][LOGIN_PAGE]', pathName]);
        setActiveTimer(false);
        return;
    }
    const token = localStorage.getItem("fitness-token");
    logs(['[TOKEN][AUTO_LOGOUT_START]', isActiveTimer.value, pathName]);
    if (token) {
        initTimer();
        timeRemaining.value = getRemainTime();
        if (timeRemaining.value <= 1) {
            let tm = setTimeout(() => {
                getAutoLogout();
                clearTimeout(tm);
            }, 3000);
            return;
        }
        const tenMinutes = 600;
        const tenMinuteAgo = timeRemaining.value - tenMinutes <= 0 ? 1 : timeRemaining.value - tenMinutes;
        const notiTimeStamp = tenMinuteAgo * 1000;

        logs(["[Token] 현재 남은 시간 : ", timeRemaining.value, "초, 10분전 남은 시간: ", tenMinuteAgo, "초", ", 로그아웃 :", timeRemaining.value, "초 뒤"]);
        const logoutTime = timeRemaining.value * 1000;

        if (timeRemaining.value > tenMinutes) {
            logs(['[TOKEN][REMAIN_TIME] 현재 남은 시간: ', Math.floor(timeRemaining.value / 60), '분 , 10분전 타이머 남은 시간: ', Math.floor(tenMinuteAgo / 60), '분']);
            remainTimer = setTimeout(() => {
                showMessage(getRemainMsg());
                clearTimeout(remainTimer);
            }, notiTimeStamp);
        } else if (timeRemaining.value >= 0 && timeRemaining.value <= tenMinutes) {
            showMessage(getRemainMsg());
        }
        logoutTimer = setTimeout(() => {
            logs(['[TOKEN][LOGOUT]']);
            getAutoLogout();
            clearTimeout(logoutTimer);
        }, logoutTime);
    }
}

const getRemainMsg = () => {
    let time = Math.floor(getRemainTime() / 60);
    let msg = time <= 0 ? getMsg('common.remain.notitme') : String(time).concat(getMsg('common.remain.minute'));
    return getMsg('common.remain.token', { '##currentTime##': moment().format('YYYY년M월D일 HH시mm분ss초'), '##time##': msg }); // 남은 시간 분 단위로 표시
}

const initTimer = () => {
    timeRemaining.value = 0;
    if (!isEmpty(remainTimer)) {
        clearTimeout(remainTimer);
        logs(['TOKEN][CLEAR_REMAIN_TIMER]']);
    } 
    if (!isEmpty(logoutTimer)) {
        clearTimeout(logoutTimer);
        logs(['TOKEN][CLEAR_LOGOUT_TIMER]']);
    }
}
const showMessage = (msg) => {
    warningMessage.value = msg;
    warningModal.value.show();
}
defineExpose({tokenExpiry});
</script>