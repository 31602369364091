import { concatMsg } from '@/utils/util';
let itm = 'router.txt.fitness';

const login = {
  path: "/login",
  name: "Login",
  meta: { title: concatMsg(['router.txt.login', itm]) },
  component: () => import(/* webpackChunkName: "login" */ `@/views/login/Login`),
};
export { login };
