import { reactive, inject } from 'vue';
import { formValidate, getMsg, isEmpty, delay } from '@/utils/util';
import commonService from "@/services/common/common-service";
import { login } from '@/api/user-api';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import { ref } from '@vue/reactivity'

const loginServies = () => {
  const { handleError, openModal, getRemainTime, warningModal, warningMessage } = commonService();
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const loading = ref(false);
  const { cookies } = useCookies();
  const isMemberidSave = cookies.get('loginSave');
  const noticeMessage = ref(null);
  const setActiveTimer = inject('setActiveTimer');
  const loginForm = reactive({
    memberid: isMemberidSave ? cookies.get('loginMemberid') : '',
    password: '',
    memberidSave: isMemberidSave ? true : false,
  })
  const loginErrors = reactive({
    memberid: false,
    password: false,
    result: false,
  })
  const resetErrors = () => {
    loginErrors.memberid = false
    loginErrors.password = false
    loginErrors.result = false
  }
  const validateForm = () => {
    resetErrors();
    let validate = true;
    if (!formValidate(loginForm.memberid, 'require')) {
      loginErrors.memberid = true;
      noticeMessage.value = getMsg('login.require.memberid');
      return false;
    }
    // if (!formValidate(loginForm.memberid, 'memberid')) {
    //   loginErrors.memberid = true;
    //   noticeMessage.value = getMsg('login.invalid.memberid');
    //   return false;
    // }
    if (!formValidate(loginForm.password, 'require')) {
      loginErrors.password = true;
      noticeMessage.value = getMsg('login.require.password');
      return false;
    }
    const errors = Object.values(loginErrors);
    errors.forEach((error) => {
      if (error) validate = false;
    })
    return validate;
  }
  const setLoginData = () => {
    let sData = { 'memberid': loginForm.memberid, 'password': loginForm.password }
    if (loginForm.memberidSave) {
      cookies.set('loginSave', loginForm.memberidSave);
      cookies.set('loginMemberid', loginForm.memberid);
    }
    return sData
  } 

  const loginSubmit = async () => {
    const isValid = validateForm();
    if (!isValid) return;
    loading.value = true;
    try {
      let data = setLoginData();
      let rst = await login(data);

      if (rst.result) {
        store.commit('userStore/setJWTToken', rst.data.token);
        await store.dispatch('userStore/setUserState');
        setActiveTimer(true);
        if (route.query.from && route.query.from.indexOf('/login') < 0) {
          await router.push({
            path: route.query.from,
            query: route.query,
          });
        } else {
          await router.push({name: 'Home'});
        }
        delay(() => { setActiveTimer(true) }, 100);
      } else {
        if (isEmpty(rst.code) || rst.code == 1) {
          loginErrors.result = true;
          noticeMessage.value = getMsg('login.txt.fail');
        } else if (!isEmpty(rst.msg) && !isEmpty(rst.code) && rst.code == 2) {
          openModal({modalType: 'W', msg: rst.msg, isShow: true, zIndex: true});
        }
      }
    } catch (error) {
      handleError("[login-service][loginSubmit]", error);
    } finally {
      loading.value = false;
    }
  }

  // store.watch((state, getters) => getters.getJWT, () => {
  //   console.log('value changes detected via vuex watch');
  // })

  const getLogout = async () => {
    loading.value = true
    return store.dispatch('userStore/logout').then(() => {
      loading.value = false;
      router.replace({
        name: "Login",        
        query: Object.assign(route.query, { mode: 'forcelogout'} )
      });
    }).catch((error) => {
      handleError("[login-service][getLogout]", error);
      loading.value = false;
    })
  }

  const getAutoLogout = async () => {
    loading.value = true
    let isTemplatePage = route.path.includes('/template');
    return store.dispatch('userStore/logout').then(() => {
      loading.value = false;
      if (isTemplatePage) {
        window.close();
      } else {
        router.replace({
          name: "Login",        
          query: Object.assign(route.query, { mode: 'forcelogout'} )
        });
      }
    }).catch((error) => {
      handleError("[login-service][getLogout]", error);
      loading.value = false;
    })
  }
  return { loginForm, loginErrors, loading, warningModal, warningMessage, noticeMessage, setActiveTimer, getRemainTime, getAutoLogout, isEmpty, getMsg, loginSubmit, getLogout };
}

export default loginServies;
