import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";
import '../public/fitness/assets/css/bootstrap.min.css';
import '../public/fitness/assets/css/app.css';
import '../public/fitness/assets/css/style.css';
import VueDOMPurifyHTML from 'vue-dompurify-html'
import tinymce from 'tinymce/tinymce';
const app = createApp(App);
app.config.globalProperties.APP_PATH = process.env.VUE_APP_IDE === 'local' ? process.env.VUE_APP_PUBLIC_PATH : '/';
app.use(VueDOMPurifyHTML);
app.use(router).use(store).mount("#layout-wrapper");
