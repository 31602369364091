import { get, post, upload } from "./axios";
import { showMessage, hideMessage, isShowValidateModal, replaceMessage } from "@/validate/validate";
import { delay, getMsg, logs } from "@/utils/util";

const getCodeList = (data) => post("/api/fitness/code/list", data); // TODO 
const customerOpinion = (data) => post("/api/fitness/customer/opinion", data); // TODO
const fileUpload = async (params, useProgress) => {
  return await upload("/api/fitness/upload", params, (event) => {
    if (useProgress) uploadProgress(event);
  });
};
const postFileDownload = async (url, params, progressbar, filename) => {
  let options = { responseType: 'blob' }
  if (progressbar) {
    options['onDownloadProgress'] = (event) => {
      if (progressbar) {
        logs(["[onDownloadProgress]", Math.round((100 * event.loaded) / event.total)]);
        progressbar.value = `${Math.round((100 * event.loaded) / event.total)}`;
      }
    }
  }
  return await post(url, params, options).then(resp => {
    let blob = new Blob([resp], { type: "application/octetstream" });
    let link = document.createElement('a');
    if (isIE()) {
      window.navigator.msSaveBlob(blob, filename);			
		} else {      
      let url = window.URL || window.webkitURL;
      link.href = url.createObjectURL(blob);
			link.download = filename;
      link.click();
		}
  }).catch(function (error) {
    logs([JSON.stringify(error)], true)
  })
}

const getFileDownload = async (url, progressbar, filename) => {
  let options = { responseType: 'blob' }
  if (progressbar) {
    options['onDownloadProgress'] = (event) => {
      if (progressbar) {
        logs(["[onDownloadProgress]", Math.round((100 * event.loaded) / event.total)]);
        progressbar.value = `${Math.round((100 * event.loaded) / event.total)}`;
      }
    }
  }
  return await get(url, options).then(resp => {
    downloadFile(resp.data, filename);
  }).catch(function (error) {
    logs([JSON.stringify(error)], true)
  })
}

const postFileUpload = async(url, params, useProgress) => {
  return await upload(url, params, (event) => {
    if (useProgress) uploadProgress(event);
  });
}

const uploadProgress = (event) => {
  let progress = Math.round((100 * event.loaded) / event.total);
  let title = getMsg('common.txt.uploading');
  let msg = `
  <div class="mb-0 font-size-16 fw-medium text-black text-center">
    <p class="mb-1 text font-medium">${title}</p>
    <div class="progress-wrap">
      <div class="progress-number ms-[calc(48%-1.25rem)]">${progress}%</div>
      <div class="progress-bar" role="progressbar" :aria-valuenow="progressbar" aria-valuemin="0" aria-valuemax="100">
        <div class="progress-bar-inner" style="width: ${progress}%"></div>
      </div>
    </div>
  </div>`;
  if (!isShowValidateModal()) showMessage(msg);
  progressControl(progress, msg);
}

const progressControl = (progress, msg) => {
  replaceMessage(msg);
  if (Number(progress) >= 100) {
    delay(() => {
      hideMessage();
    }, 600);
  }
}

const fileDownload = async(url, filename) => {
  get(url, { responseType: 'blob' }).then(resp => {
    downloadFile(resp.data, filename);
  }).catch(function (error) {
    logs([JSON.stringify(error)], true)
  })
};

function isIE() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  var trident = ua.indexOf('Trident/');
  return (msie > 0 || trident > 0);
}

function downloadFile(data, filename) {
  let link = document.createElement('a');
  var blob = new Blob([data], { type: "application/octetstream" });
  if (isIE()) {
    window.navigator.msSaveBlob(blob, filename);			
  } else {
    let url = window.URL || window.webkitURL;
    link.href = url.createObjectURL(blob);
    link.download = filename;
    link.click();
  }
}

export {
  customerOpinion,
  getCodeList,
  getFileDownload,
  postFileDownload,
  postFileUpload,
  fileUpload,
  fileDownload,
};
