<template>
	<div class="modal fade" tabindex="-1" aria-hidden="true" :ref="(el) => warningModal = el" role="dialog" :style="{ 'z-index': zIndex > 0 ? zIndex : '' }">
		<div class="modal-dialog modal-dialog-centered modal-sm">
			<div class="modal-content">
				<div class="modal-body">
					<div v-if="useSlotContent"> 
						<slot name="modalContent"></slot><!-- 업무 영역에서 정의할 Content 영역 -->
					</div>
					<div v-else v-html="dynamicContent"></div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-primary w-lg" v-if="!btnCloseHide" @click="hide">{{ getMsg('common.txt.ok') }}</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from "vue";
import { Modal } from "bootstrap";
import { getMsg, delay, stringToBoolean, isEmpty  } from '@/utils/util';
import { onMounted, onBeforeUnmount, nextTick } from '@vue/runtime-core';

let modal = null;
const warningModal = ref(null);
const useSlotContent = ref(true);
const dynamicContent = ref(null);
const zIndex = ref(0);
const props = defineProps({isHideClose: { type: Boolean, default: false }, backdrop: { type: String, default: 'true' }, focus: { type: String, default: 'false'}});
let btnCloseHide = ref(true);
const hide = () => {
	modal.hide();
	zIndex.value = 0;
};
const show = () => {
	warningModal.value.addEventListener('show.bs.modal', backdropHandler, { once: true });
	nextTick(() => { 
		modal = new Modal(warningModal.value, { focus: stringToBoolean(props.focus), backdrop: stringToBoolean(props.backdrop) });
		btnCloseHide.value = props.isHideClose;
		modal.show();
		nextTick(() => {
			warningModal.value.addEventListener('hidden.bs.modal', removeModal, { once: true });
			warningModal.value.addEventListener('hide.bs.modal', () => { document.activeElement.blur(); }, { once: true });
		});
	});	
};
const removeModal = () => {
    if (modal) {
		modal.hide();
		modal.dispose();
		modal = null;
		useSlotContent.value = true;
    }
}
const setZindex = (v = 2000) => {
	zIndex.value = v;
}
const setMsg = (msg) => {
	dynamicContent.value = msg;
	useSlotContent.value = false;
}
const backdropHandler = () => {
	delay(() => {
		const backdrops = document.querySelectorAll('.modal-backdrop');
		const lastBackdrop = backdrops[backdrops.length - 1];
		if (lastBackdrop) {
			if (zIndex.value) {
				lastBackdrop.style.zIndex = zIndex.value - 1;
			} else {
				lastBackdrop.style.removeProperty('z-index');
			}
		}
	}, 1);
}
const isShow = () => {
	return !isEmpty(modal)
}
onMounted(() => {});
onBeforeUnmount(() => {});
defineExpose({hide, show, isShow, setMsg, setZindex});
</script>
<style scoped></style>
