import { createRouter, createWebHistory } from "vue-router";
import { indexRouter, systemErrorRouter, notFoundRouter } from "./common-router";
import { fitnessBoardListRouter, fitnessBoardModifyRouter } from "./board-router";
import { login } from "./user-router";
import store from "../store/index";

const routes = [ 
  indexRouter, systemErrorRouter, notFoundRouter, 
  login,
  fitnessBoardListRouter, fitnessBoardModifyRouter,  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  }
});

const publicPages = ["/fitness/login"];

router.beforeEach((to, from, next) => {
  let title = typeof to.meta.title === 'function' ? to.meta.title(to) : to.meta.title;
  document.title = title;
  localStorage.setItem("prevPage", from.fullPath);
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isLogin = store.getters["userStore/isLogin"];
  const authRequired = publicPages.includes(to.path);
  const routerName = to.name || '';

  if (!routerName) {
    next("/");
  } else if (requiresAuth && !isLogin) {
    const query = { mode: 'forcelogout' };
    next({ name: "Login", query });
  } else if (!requiresAuth && isLogin && authRequired) {
    next("/");
  } else if (isLogin) {
    next();
  } else if (!isLogin && to.query.redirect === "Y") {
    const query = Object.assign(from.query, { from: from.path, mode: 'forcelogout' });
    next({ name: "Login", query });
  } else {
    next();
  }
});

export default router;
