import { reactive, ref } from "@vue/reactivity";
import { useRouter, useRoute } from "vue-router";
import { delay, getMsg, isEmpty, isEmptyObject, formValidate, comma, uncomma, removeSpace, bizNoFormatter, formatDate, deepClone, reduceFileName, logs } from '@/utils/util';
import { useStore } from "vuex";
import { v4 as uuid } from "uuid";
import { getCodeList, fileUpload, fileDownload, getFileDownload, customerOpinion } from "@/api/common-api";
import { showMessage, fileValidate, validateModal, validateMessage, } from "@/validate/validate";
import moment from 'moment-timezone';
import { uploadCancel } from "@/api/axios";
import jwtDecode from "jwt-decode";

// 공통 서비스
const commonService = () => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const searchtext = ref("");
  const loading = ref(false);
  const isfetching = ref(true);
  const viewModel = ref({});
  const delFiles = ref([]);
  const fileError = ref([]);
  const pView = ref([]);
  const codeData = ref({});
  const pagination = ref({});
  const modal = ref(null);
  const modalMessage = ref(null);  
  const modalType  = ref(null); 
  const modalParam  = ref(null); 
  const warningModal = ref(null);
  const warningMessage = ref(null);
  const confirmModal = ref(null);
  const confirmMessage = ref(null);
  const confirmModalType = ref(null);
  const confirmModalParam = ref(null);
  const largeModalMessage = ref(null);
  const largeModalType = ref(null);
  const largeModal = ref(null);
  const largeModalView = ref(null);
  const companyInfoModal = ref(null);
  const progressbar = ref(0);
  let win = ref({});
  const sort = ref('WRITE_UNIXTIME');
  const sidx = ref(-1);
  const getMe = ref(store.getters["userStore/getMe"]);
  pagination.value = { page: 1, limit: 10, total: 0 };  
  let opinionFormError = ref({OPINION_DETAIL: false, EMAIL1: false, EMAIL2: false });
  const imageMaxSize = 3 * 1024 * 1024; // 3MB 제한
  const fileMaxSize = 20 * 1024 * 1024; // 20MB 제한

  // 리스트 기본 정보
  let defaultPage = reactive({
    BOARD: { 
      page: 1, // 페이지
      itemsperpage: 10, // 리스트 갯수 // 10으로 변경 해야 함.
      search: '',  // 검색어
    }
  });
  // modalType: 'L','C','W','B' // 라지, 컨펌, 워닝, 베이직
  // isShow: true, false
  // type: 0,1,2,3
  // msg: 모달입니다.
  // zIndex: true, false (z-index 설정 여부)
  // zIndexv: z-index 값 직접 설정 (숫자 3000)
  // param: {} 모달에 param을 설정할 경우
  // ex) {modalType: 'L', isShow: true, type: '0', msg: 'merong', zIndex: true, zIndexv: 300000, param: {data: ['1','2']}}

  const openModal = (item) => {
    let type = item.modalType || 'X';
    let isShow = item.isShow;
    let modalItem;
    if (type == 'L') { // 라지모달     
      modalItem = largeModal;
      if (!isEmpty(item.msg)) largeModalMessage.value = item.msg;
      if (!isEmpty(item.type)) largeModalType.value = item.type;
    } else if (type == 'C') { // 컴펌모달
      modalItem = confirmModal;
      if (!isEmpty(item.msg)) confirmMessage.value = item.msg;
      if (!isEmpty(item.type)) confirmModalType.value = item.type;
      if (!isEmpty(item.param)) confirmModalParam.value = item.param;
    } else if (type == 'W') { // 워닝모달
      modalItem = warningModal;
      if (!isEmpty(item.msg)) warningMessage.value = item.msg;
    } else if (type == 'B') { // 베이지모달
      modalItem = modal;
      if (!isEmpty(item.msg)) modalMessage.value = item.msg;
      if (!isEmpty(item.type)) modalType.value = item.type;
      if (!isEmpty(item.param)) modalParam.value = item.param;
    }
    if (!isEmpty(item.zIndex) && item.zIndex) {
      if (!isEmpty(item.zIndexv)) {
        modalItem.value.setZindex(item.zIndexv);
      } else {
        modalItem.value.setZindex();
      }
    }
    if (isShow) {
      modalItem.value.show();
    } else {
      modalItem.value.hide();
    }
  }

  const resetError = (formerror) => {
    let errors = JSON.parse(JSON.stringify(formerror.value));
    for (const [k, v] of Object.entries(errors)) {
      formerror.value[k] = false;
    }
  }

  // 공통 코드 조회
  const codeList = async (param) => {
    loading.value = true;
    try {
      let rst = await getCodeList(param);
      if (rst.result) {
        codeData.value = {};
        if (!isEmpty(rst.data)) {
          let append = '_CDS';
          codeData.value = rst.data.reduce(function(o, i) {
            if (!o[i.TYPE + append]) o[i.TYPE + append] = [];
            return o[i.TYPE + append].push(i), o
          }, {});
        }
      } else {
        openModal({modalType: 'W', msg: rst.msg, isShow: true, zIndex: true});
      }
    } catch (error) {
      handleError("[common-service][codeList]", error);
    } finally {
      loading.value = false;
    }
  };

  // 파일 객체만을 저장시
  const handleFile = async(event, target, idx) => {
    let files = event.target.files;
    if (isEmpty(files)) return
    viewModel.value[target].FILES[idx] = files[0];
    initFile(event);
  }  

  // 파일다운로드
  let handleFileDownload = async(file, idx = 0) => {
    if (isEmpty(file)) return;
    let url = window.location.protocol.concat('//', window.location.host, '/api/file/', file[idx].FILE_KEY);    
    // let type = file[0].FILE_TYPE.indexOf('image') > -1 ? 'image': 'file';
    fileDownload(url, file[idx].FILE_NAME);
  }  

  // 파일 업로드
  let handleFileUpload = async (e, idx, item, type, valid, done, isinit = false) => {
    if (!e) return;
    let files = e.target.files;    
    let accept = e.target.accept;
    let id = isEmpty(accept) ? 'file' : (accept.includes("image") ? 'img' : 'file');
    let isValid = true;

    // 사용자정보 체크
    if (isEmpty(getMe.value)) return openModal({modalType: 'W', isShow: true, msg: getMsg('common.no.userinfo'), zIndex: true, zIndexv: 30000});

    // 파일 체크
    const file = files[0];
    if (!file) return;

    // 이미지, 파일 확장자 체크
    if (valid) isValid = fileValidate(id, files[0]);
    if (!isValid) return;

    // 특정 파일 확장자 체크
    if (!isEmpty(accept) && accept.split('/')[1] != '*' && file.type.indexOf(accept.split('/')[1]) < 0) {
      return openModal({modalType: 'W', isShow: true, msg: getMsg('common.only.filetype', { '##filetype##': accept.split('/')[1] }), zIndex: true, zIndexv: 30000});
    }

    // 이미지만 업로드 가능한데 파일을 올렸을 경우
    if (!file.type.startsWith("image/") && id == 'img') return openModal({modalType: 'W', isShow: true, msg: getMsg('common.upload.onlyimage'), zIndex: true, zIndexv: 30000});

    // 파일 사이즈 체크
    if (file.size > (id == 'img' ? imageMaxSize : fileMaxSize)) return openModal({modalType: 'W', isShow: true, msg: getMsg('common.'+ (id == 'img' ? 'image' : 'file') +'.limit'), zIndex: true, zIndexv: 30000});

    try {
      loading.value = true;
      let form = new FormData();
      form.append('id', getMe.value.id);
      form.append('type', type);
      form.append('file', file);

      let rst = await fileUpload(form, true);
      logs(['[common-service][handleFileUpload] results : ', rst])
      if (rst.result) {
        if (done) done(item, rst.data, idx);
      } else {
        openModal({modalType: 'W', isShow: true, type: '0', msg: rst.msg, zIndex: true, zIndexv: 30000});
        if (done) done();
      }
    } catch (error) {
      handleError("[common-service][handleFileUpload]", error);
    } finally {
      if (isinit) initFile(e);
      loading.value = false;
    }
  }

  // 파일 포맷 추가 
  let addFileFormat = (item) => {
    item.push({ FILE_INFO: [], FIELD_NAME: '', FILE_KEY: ''});
  }

  // 에디터 이미지 업로드
  let handleEditorImageAdded = async(file, type, done) => {
    let result = {};
    // 사용자정보 체크
    if (isEmpty(getMe.value)) return openModal({modalType: 'W', isShow: true, msg: getMsg('common.no.userinfo'), zIndex: true});

    // 파일 체크
    if (!file) return;

    // 이미지만 업로드 가능한데 파일을 올렸을 경우
    if (!file.type.startsWith("image/")) return openModal({modalType: 'W', isShow: true, msg: getMsg('common.upload.onlyimage'), zIndex: true});

    // 파일 사이즈 체크
    if (file.size > imageMaxSize) return openModal({modalType: 'W', isShow: true, msg: getMsg('common.image.limit'), zIndex: true});

    try {
      loading.value = true;
      let form = new FormData();
      form.append('id', getMe.value.id);
      form.append('type', type);
      form.append('file', file);

      let rst = await fileUpload(form, true);
      logs(['[handleEditorImageAdded] results : ', rst])
      result = rst;
    } catch (error) {
      handleError("[common-service][handleEditorImageAdded]", error);
    } finally {
      loading.value = false;
      done(result);
    }
  }

  // 파일 초기화
  let initFile = (e) => {
    e.target.value = ''
  }

  // 파일 삭제
  let removeFile = (data, idx, temp = true) => {
    if (!isEmpty(data[idx].FILE_KEY)) delFiles.value.push(data[idx].FILE_KEY);
    data.splice(idx, 1);
    if (data.length < 1 && temp) addTempFile(data);
  }

  // 파일 추가
  let addTempFile = (data) => {
    data.push({ FILE_KEY: '', FILE_NAME: '', FILE_INFO: {} })
  }

  const getCdName = (cd, type) => {
    let data = codeData.value[type].filter(e => { return e.CD == cd });
    return isEmpty(data) ? '' : data[0].CD_NAME;
  }

  const handleError = (msg, error) => {
    logs([msg, error]);
    openModal({modalType: 'W', msg: getMsg('common.txt.error'), isShow: true, zIndex: true});
  }

  let assertMaxChars = (e, num = 500) =>{
    let v = e.target.value;
    e.target.value = submitMemo.value.CONTS = v.length >= num ? v.substring(0, num) : v;    
  }

  const handleOnlyDigit = (e) => {
    e.target.value = comma(Number(e.target.value.replace(/[^0-9]/g, "")));
  }

  const handleOnlyNumber = (e) => {
    e.target.value = comma(e.target.value.replace(/[^0-9]/g, ""));
  }

  const handleOnlyNumberNoComma = (e) => {
    e.target.value = Number(e.target.value.replace(/[^0-9]/g, ""));
  }

  const handleOnlyNumberComma = (e) => {
    const onlyNumber = e.target.value.replace(/[^0-9]/g, "");
    const numberWithComma = onlyNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    e.target.value = numberWithComma;
  }

  // 프로그레스바 파일 다운로드
  const progressFileDownload = async(type, url, filename) => {
    loading.value = true;
    progressbar.value = 0;
    largeModalType.value = type;
    largeModal.value.hideClose();
    largeModal.value.show();
    try {
      await getFileDownload(url, progressbar, filename);
    } catch (error) {
      handleError("[common-service][progressFileDownload]", error);
    } finally {
      largeModal.value.hide();
      loading.value = false;
      isfetching.value = false;
    }
  }

  // 업로드 취소
  const uploadCancelJob = () => {
    uploadCancel();
    validateModal.value.hide();
  }

  // 특정 라우터로 이동
  const goRouter = (rn, param = {}) => {
    let data = { name:  rn};
    if (!isEmpty(param)) data = Object.assign(data, param);
    router.push(data);
  }

  // 홈 화면으로 이동
  const goHome = async() => {
    await router.replace({ name: "Home" });
    router.go(0);
  }

  //일자 1~31일 세팅
  const getDays = () => {
    var getDaysArray 	= new Array();
    for (var i=1; i<=31; i++) {
      var getDaysObj = new Object();        
      getDaysObj.CD = i;
      getDaysObj.CD_NAME	= i;        
      getDaysObj = JSON.stringify(getDaysObj);
      getDaysArray.push(JSON.parse(getDaysObj));
    }
    return getDaysArray;
  }

  const openCompanyInfoModal = () => {
    companyInfoModal.value.open();
  }

  // ex) routerparam: { name: 'HomepageDesignEditor', params: { hospcd: props.hospcd }, query: { owner: props.owner } }
  const openWinRouter = (routerParam, isMulti = false) => {    
      let r = router.resolve(routerParam);
      let childName = routerParam.name;
      if (isMulti) childName = childName.concat('_', uuid());
      win.value[childName] = window.open(r.href, childName);
      window.addEventListener("message", rcvChildWin, { once: true });
  }

  // ex) { event: 'reload', param: { hospcd: '1111' } } 
  const rcvChildWin = (rcv) => { // 자식창으로 부터 받은 메시지
    let ev = rcv.data.event;
    // if (ev == 'goHome') {
    //   if (rcv.data.param.hospcd == props.hospcd) goRouter('Home');
    // }
  }

  const getHomepageUrl = (data) => {
    var hostname = location.hostname.split(".").slice(-3).join(".");
    return 'https://'.concat(data.HOMP_HOST, '.', hostname, '/build/', data.HOMP_HOST, '/index.html');
  }

  // 토큰 남은 시간 구하기
  const getRemainTime = () => {
    const token = localStorage.getItem("fitness-token");
    const decodedToken = jwtDecode(token);
    const expTime = decodedToken.exp; // 토큰 만료 시간 (초 단위)
    const currentTime = Math.floor(Date.now() / 1000); // 현재 시간 (초 단위)
    const remainTime = expTime - currentTime <= 0 ? 1 : expTime - currentTime;
    return remainTime; // 남은 시간 (초 단위)
}

  return {
    store,
    router,
    route,
    loading,
    isfetching,
    viewModel,
    fileError,
    pView,
    searchtext,
    pagination,
    modal,
    modalMessage,
    modalType,
    modalParam,
    warningModal,
    warningMessage,
    confirmModal,
    confirmMessage,
    confirmModalType,
    confirmModalParam,
    largeModalMessage,
    largeModalType,
    largeModal,
    largeModalView,   
    companyInfoModal, 
    defaultPage,
    sort,
    sidx,
    codeData,
    getMe,
    validateModal, 
    validateMessage,
    progressbar,
    moment,
    opinionFormError,
    resetError,
    getRemainTime,
    getHomepageUrl,
    openWinRouter,
    goHome,
    openModal,
    goRouter,
    reduceFileName,
    handleOnlyDigit,
    uploadCancelJob,
    progressFileDownload,
    handleOnlyNumberNoComma,
    handleOnlyNumberComma,
    handleOnlyNumber,
    deepClone,
    uuid,
    assertMaxChars,
    addFileFormat,
    handleError,
    formatDate,
    initFile,
    removeSpace,
    formValidate, 
    comma,
    uncomma,
    showMessage,
    handleFile,
    handleFileDownload,
    handleFileUpload,
    handleEditorImageAdded,
    removeFile,
    addTempFile,
    codeList,
    getMsg,
    logs,
    isEmpty,
    isEmptyObject,
    bizNoFormatter,
    getCdName,
    getDays,
    openCompanyInfoModal
  };
};

export default commonService;
