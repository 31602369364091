import axios from "axios";
import router from "../router";
import { delay, isEmpty, logs } from "@/utils/util";
import store from "../store/index";
let abortController;

const toLogin = (param = {}) => {
  let query = { redirect: router.currentRoute.fullPath, mode: 'forcelogout' };
  if (!isEmpty(param)) Object.assign(query, param);
  router.replace({
    name: "Login",
    query: query
  });
};

const errorHandle = (status, other) => {
  switch (status) {
    case 401:
      toLogin();
      break;
    case 403:
      localStorage.removeItem("fitness-token");
      localStorage.removeItem("fitness-userState");
      delay(() => {
        toLogin();
      }, 1000);
      break;
    case 404:
      router.replace({
        name: "NotFound",
      });
      break;
    case 500 || 502: 
      router.replace({
        name: "SystemError",
      });
      break;
    default:
  }
};

const instance = axios.create({
  baseURL: process.env.VUE_APP_DOMAIN,
  timeout: 1000 * 30,
  headers: { "Content-Type": "application/json;charset=UTF-8" }
});

instance.interceptors.request.use(
  (config) => {
    config.headers.common["user-token"] = store.getters["userStore/getJWT"];
    return config;
  },
  (error) => Promise.error(error)
);

instance.interceptors.response.use(
  (response) => {
    // let uToken = response.config.headers["user-token"];
    // let jwt = store.getters["userStore/getJWT"];
    // if (uToken && !uToken != jwt) {
    //   store.commit("userStore/setJWTToken", uToken);
    // }
    if (response.data && !response.data.result) {
      if (response.data.code && ['notvalid', 'expired','noinfo','nocontract'].includes(response.data.code)) {
        store.commit('userStore/clearJWTToken')
        toLogin({code: response.data.code });
        return new Promise(() => {});
      }
    }
    return response.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response);
  },
  (error) => {
    const { response } = error;
    if (response) {
      errorHandle(response.status, response.data.message);
      return Promise.reject(response);
    } else if (!isEmpty(error.message) && error.message == 'canceled') { // 사용자 취소일 경우
      return Promise.resolve();
    } else {
      router.replace({
        path: "/404",
      });
    }
  }
);

export function get(url, options) {
  return new Promise((resolve, reject) => {
    let sd = new Date().getTime();
    instance.get(url, options).then((response) => {
        logs(["[API] CALL GET RESULT :", url, response, new Date().getTime() - sd, ' ms']);
        resolve(isEmpty(response) ? {} : response);
      }).catch((err) => {
        logs(["[API] CALL GET ERROR :", url, err], true);
        reject(err);
      });
  });
}

export function post(url, data = {}, options = {}) {
  return new Promise((resolve, reject) => {
    let sd = new Date().getTime();
    instance.post(url, data, options).then(
      (response) => {
        logs(["[API] CALL POST RESULT :", url, data, response, new Date().getTime() - sd, ' ms']);
        resolve(isEmpty(response) ? { result: false } : response.data);
      },
      (err) => {
        logs(["[API] CALL POST ERROR :", url, err], true);
        reject(err);
      }
    );
  });
}

export function upload(url, data, onUploadProgress) {
  abortController = new AbortController();
  return new Promise((resolve, reject) => {
    instance.post(url, data, {
        headers: { "Content-Type": "multipart/form-data" },
        signal: abortController.signal,
        onUploadProgress,
      }).then(
        (response) => {
          logs(["[API] CALL UPLOAD RESULT :", url, response]);
          if (abortController.signal.aborted) { // 업로드 취소일 경우
            resolve({ result: false, mode: 'cancel', 'msg': '사용자 요청에 의해 취소 되었습니다.' });
          } else {
            resolve(isEmpty(response) ? { result: false } : response.data);
          }
        },
        (err) => {
          logs(["[API] CALL UPLOAD ERROR :", url, err], true);
          reject(err);
        }
    );
  });
}

export function uploadCancel() {
  if (!abortController.signal.aborted) abortController.abort();
}

export default instance;