module.exports = {
    // replace character ex) ##good##    
    /* LOGIN */
    'login.require.memberid': '아이디를 입력해 주세요.',
    'login.require.password': '비밀번호를 입력해 주세요.',
    'login.txt.fail': '아이디와 비밀번호를 확인해 주세요.',
    'login.txt.info': '아이디/비밀번호는 씨트론에서 발급 된 계정을 사용합니다.',
    'login.txt.name': '로그인',
    'logout.txt.name': '로그아웃',    
    'login.save.id': '아이디 저장',
    /* 'login.invalid.memberid': '이메일이 형식이 맞지 않습니다.', */

    /* COMMON */
    'common.txt.fitness': '피트니스',
    'common.design.modify': '디자인 수정',
    'common.txt.newwin': '새창',
    'common.txt.total': '전체',
    'common.valid.01': '이용약관 동의를 해주세요.',
    'common.req.title': '##title##을 입력해 주세요.',
    'common.site.title': '피트니스',
    'common.en.search': 'Search',    
    'common.txt.select': '선택',
    'common.txt.search': '검색',    
    'common.search.detail': '상세검색',
    'common.all.selet': '전체 선택',
    'common.open.newwin': '새창열기',    
    'common.txt.status': '상태',
    'common.txt.service': '서비스',
    'common.not.contract': '미계약',
    'common.txt.startdt': '시작일',
    'common.txt.enddt': '종료일',    
    'common.txt.confirmok': '확인 되었습니다.',
    'common.no.userinfo': '사용자 정보를 확인 해주세요.',
    'common.name.hospital': '병원이름',
    'common.required.items': '필수입력항목',
    'common.view.tooltip': '도움말 보기',    
    'common.txt.bizno': '사업자등록번호',    
    'common.confirm.duplicate': '중복확인',    
    'common.cert.biz': '사업자등록증',    
    'common.add.file': '첨부',    
    'common.txt.address': '주소',    
    'common.detail.address': '상세주소',
    'common.rprs.tel': '대표번호',
    'common.fax.tel': '팩스번호',
    'common.rprs.email': '대표이메일',
    'common.ssl.protocol': 'https://',
    'common.txt.cancel': '취소',
    'common.txt.save': '저장',
    'common.txt.nosave': '저장안함',
    'common.txt.modify': '수정',
    'common.txt.del': '삭제',
    'common.txt.done': '되었습니다.',
    'common.fill.input': '을(를) 입력 해주세요.',
    'common.fill.select': '을(를) 선택해 주세요.',
    'common.do.action': '을(를) 해주세요.',
    'common.not.exists': '이(가) 없습니다.',
    'common.error.expired': '이용 시간이 만료되어 자동 로그아웃 됩니다.',
    'common.error.notvalid': '유효 하지 않은 토큰 정보 입니다.',
    'common.error.noinfo': '인증 정보가 없습니다.',    
    'common.error.nocontract': '홈페이지 사용권한이 없는 병원입니다.<br/>계약 기간을 확인 해주세요.',
    'common.txt.hospitallist': '병원 목록',
    'common.txt.personcnt': '명',
    'common.txt.reg': '등록',
    'common.info.modify': '정보수정',
    'common.txt.error': '서버 오류가 발생 했습니다.<br/>관리자에게 문의 해주세요.',    
    'common.unit.money': '원',
    'common.txt.month': '월',
    'common.txt.unit': '건',
    'common.auto.input': '자동입력',
    'common.txt.approval': '결제',
    'common.txt.staff': '인사',
    'common.txt.schedule': '일정',
    'common.txt.domain': '도메인',
    'common.txt.upload': '업로드',
    'common.txt.add': '추가',    
    'common.txt.init': '초기화',    
    'common.txt.history': '수정내역',
    'common.info.save': '정보저장',
    'common.txt.ok': '확인',    
    'common.only.filetype': '##filetype## 확장자만 업로드 가능합니다.',
    'common.check.fileext': '파일 확장자를 확인해주세요.',
    'common.check.fileinfo': '파일 정보를 확인 해주세요.',
    'common.image.limit': '이미지는 3MB 이하로 등록 가능합니다.',
    'common.file.limit': '파일은 20MB 이하로 등록 가능합니다.',
    'common.upload.onlyimage': '이미지만 등록 가능합니다.',
    'common.error.upload': '파일 전송 중 에러가 발생 했습니다.',
    'common.text.limit': '글자는 10,000자 이상 등록할 수 없습니다.',
    'common.txt.shortcut': '바로가기',
    'common.txt.notliveopen': '홈페이지 URL 생성중입니다',
    'common.txt.notbuild1': '홈페이지 반영이 필요합니다.',
    'common.txt.notbuild2': '<p class="mt-2 text-center font-size-14 fw-normal">홈페이지 디자인 수정 후 반영 버튼을 누르시면 홈페이지를 확인하실 수 있습니다</p>',
    'common.info.mgmt' : '필수 정보 관리',
    'common.select.del' : '선택삭제',
    'common.page.allpre': '맨 앞으로',
    'common.page.pre': '이전',
    'common.page.allnext': '맨 뒤로',
    'common.page.next': '다음',

    'common.need.login': '로그인이 필요한 서비스입니다.',
    'common.txt.write': '작성',
    'common.txt.etc': '비고',
    'common.txt.andsoon': '등',
    'common.view.newwin': '새창보기',    
    'common.txt.move': '이동',
    'common.txt.input': '입력',
    'common.txt.everymonth': '매월',
    'common.txt.day': '일',
    'common.txt.onlynum': '숫자만 입력',
    'common.view.manager': '담당자 보기',
    'common.txt.contents': '내용',
    'common.date.save': '저장일',
    'common.txt.manager': '담당자',
    'common.fill.empty': '내용을 입력 해주세요.',
    'common.txt.exec': '실행',
    'common.eng.error': 'error',
    'common.txt.404': '404',
    'common.txt.500': '500',
    'common.txt.notfound': '죄송합니다.<br>요청하신 페이지를 찾을 수 없습니다.',
    'common.txt.notwork': '죄송합니다.<br>페이지가 작동하지 않습니다.',
    'common.notwork.info': '서버 통신의 timeout 시간 지연 오류, 서버 트래픽 과부하,<br/>서버 언어의 구문 에러 등 서버 내부의 오류로 인해 정상 작동하지 않습니다.',
    'common.notfound.info': '찾으시려는 페이지의 주소가 잘못 입력되었거나,<br>변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.',
    'common.move.home': '홈으로 이동',
    'common.move.prev': '이전 페이지',
    'common.txt.download': '다운로드',    
    'common.txt.uploading': '업로드중입니다.',    
    'common.txt.noAppl': '해당없음',
    'common.txt.filedownload': '파일 다운로드',    
    'common.txt.close': '닫기',
    'common.modify.done': '수정 되었습니다.',    
    'common.txt.sir': '님',
    'common.txt.send': '전송',
    'common.customer.contract': '고객센터 문의',    
    'common.customer.opinion': '사용자 의견',    
    'common.opinion.type': '의견유형',
    'common.opinion.detail': '상세내용',
    'common.add.file': '파일첨부',
    'common.upload.file': '파일 올리기',    
    'common.reply.mail': '답변수신 메일',    
    'common.email.direct': '직접입력',    
    'common.opinion.option1': '기능문의',
    'common.opinion.option2': '기능제안',
    'common.opinion.option3': '오류제보',
    'common.opinion.option4': '기타문의',     
    'common.email.option1': 'naver.com',     
    'common.email.option2': 'daum.net',     
    'common.email.option3': 'gmail.com',     
    'common.email.option4': 'kakao.com',     
    'common.send.opinion': '보내기',
    'common.opinion.detailplaceholder': '문의하시려는 내용을 되도록 상세히 작성해 주세요.\n내용 이해를 위해 화면의 사진이나 캡쳐 화면, 영상을 첨부하여 주시면 더욱 정확한 답변이 가능합니다.',
    'common.opinion.detailrequired': '의견 내용을 입력해 주세요.',
    'common.opinion.emailrequired': '답변 수신 메일을 입력해 주세요.',
    'common.opinion.result': '의견을 전달하였습니다.<br>모든 의견은 소중히 확인하도록 하겠습니다.<br/>(단, 문의 외 의견에 대한 답변은 필요시에만 전달 드리오니 양해 부탁드립니다.)<br/>감사합니다.',
    'common.forward.home': '홈 화면으로 이동합니다.',    
    'common.txt.image': '이미지',
    'common.txt.year': '년',
    'common.txt.complete': '완료',
    'common.remain.token': '<b>현재시각: ##currentTime##</b><br/><br/> <font color="blue"><b>##time##</b> 계정이 자동 로그아웃 됩니다.</font><br/>편집중인 화면이 있을 경우 임시저장하세요.',
    'common.remain.notitme': '사용 시간이 만료되어',
    'common.remain.minute': '분 후',
    'common.txt.help': '도움말',
    'common.txt.list': '목록',
    'common.input.search': '검색어를 입력하세요',
    'common.token.expired': '이용 시간이 만료되어 자동 로그아웃 됩니다.',
    'common.txt.max': '최대',
    'common.txt.count': '개',

    /* BOARD */
    'board.txt.mgmt': '공지사항 게시글 관리',
    'board.txt.register': '게시글 등록',
    'board.txt.modify': '게시글 수정',
    'board.delete.oneconfirm': '게시글을 삭제 하시겠습니까?',
    'board.complete.register': '게시글 등록이 완료되었습니다.',
    'board.complete.modify': '게시글 수정이 완료되었습니다.',
    'board.list.header': '번호|제목|등록자|등록일',
    'board.notice.reg': '공지등록',
    'board.txt.use': '공개',
    'board.txt.notuse': '비공개',
    'board.list.empty': '등록된 게시글이 없습니다.',
    'board.no.selected': '삭제할 게시글을 선택해 주세요.',
    'board.delete.confirm': '선택한 게시글을 삭제 하시겠습니까?',
    'board.reg.boardinfo': '게시글 정보를 등록하시겠습니까?',
    'board.modify.boardinfo': '게시글 정보를 수정하시겠습니까?',
    'board.txt.reg': '게시판 등록',
    'board.txt.mod': '게시판 수정',
    'board.txt.useyn': '공개여부',
    'board.add.notice': '공지등록',
    'board.txt.title': '제목',
    'board.upload.limit': '파일은 총 5개, 종합 50MB까지 올릴 수 있습니다.',
    'board.length.limit': '첨부파일은 5개까지 등록 가능합니다.',
    'board.size.limit': '제한된 크기(50MB)를 초과하여<br/>업로드 할 수 없습니다.',    
    'board.move.deleted': '입력한 내용이 삭제 됩니다. 이동하시겠습니까?',
    
    /* LEFT */
    'left.menu.board': '공지사항 게시글 관리',
    'left.menu.customer': '고객센터',
    'left.menu.opinion': '사용자의견',
    'left.menu.setup': '설정',    

    /* ROUTER */
    'router.txt.fitness': '피트니스',
    'router.txt.login': '로그인',     
    'router.txt.home': '방문자 현황',
    'router.popup.reg': '팝업등록',
    'router.board.modify': '게시글수정',
    'router.board.reg': '게시글등록',    
    'router.txt.hyphen': '|',    

    /* HOMEPAGEINFO */
    'homepageinfo.txt.title': '홈페이지 필수 정보',
    'homepageinfo.txt.homepage_name': '홈페이지 명',
    'homepageinfo.txt.host_name': '홈페이지 주소',
    'homepageinfo.txt.host_name_valid': '가능한 주소입니다. 주소(URL) 설정 이후에는 변경 불가합니다.',
    'homepageinfo.txt.host_name_notvalid': '이미 사용중인 주소입니다.',
    'homepageinfo.txt.host_name_notvalid2': '서브도메인은 3자 이상이어야 합니다.',
    'homepageinfo.txt.host_name_notvalid3': '영문 소문자와 숫자만 가능합니다.',
    'homepageinfo.txt.business_tel': '병원 대표 전화(상담)',
    'homepageinfo.txt.working_time': '진료시간',
    'homepageinfo.txt.working_time_limit': '최대 7개 등록 가능합니다.',
    'homepageinfo.txt.working_time_add': '항목추가',
    'homepageinfo.txt.working_desc': '진료시간 안내문구',
    'homepageinfo.txt.logo': '로고(대표이미지)',
    'homepageinfo.txt.logo_limit': 'png 파일만 가능',
    'homepageinfo.txt.logo_upload': '이미지 올리기',
    'homepageinfo.txt.useyn': '홈페이지 공개여부',
    'homepageinfo.txt.uselabel': '공개',
    'homepageinfo.txt.notuselabel': '비공개',
    'homepageinfo.txt.domain_name': '.medisay.co.kr',
    'homepageinfo.duplicate.host_name': '홈페이지 주소를 확인 해주세요.',
    'homepageinfo.working_time.limit':'추가할 수 있는 최대 개수를 초과하였습니다',
    'homepageinfo.placeholder.homepage_name': '홈페이지명을 입력하세요',
    'homepageinfo.placeholder.host_name': '원하는 URL 입력',
    'homepageinfo.placeholder.business_tel': '병원 전화번호 입력',
    'homepageinfo.placeholder.working_time[0].title': '최대 10자(ex 평일)',
    'homepageinfo.placeholder.working_time[0].desc': 'ex) 10:00~17:00',
    'homepageinfo.placeholder.working_time[1].title': '최대 10자(ex 공휴일)',
    'homepageinfo.placeholder.working_time[1].desc': '',
    'homepageinfo.placeholder.working_time[2].title': '최대 10자(ex 평일)',
    'homepageinfo.placeholder.working_time[2].desc': '',
    'homepageinfo.placeholder.working_time[3].title': '최대 10자(ex 평일)',
    'homepageinfo.placeholder.working_time[3].desc': '',
    'homepageinfo.placeholder.working_time[4].title': '최대 10자(ex 평일)',
    'homepageinfo.placeholder.working_time[4].desc': '',
    'homepageinfo.placeholder.working_time[5].title': '최대 10자(ex 평일)',
    'homepageinfo.placeholder.working_time[5].desc': '',
    'homepageinfo.placeholder.working_time[6].title': '최대 10자(ex 평일)',
    'homepageinfo.placeholder.working_time[6].desc': '',
    'homepageinfo.placeholder.working_desc': 'ex) 일요일, 공휴일, 국경일은 휴진입니다.',
    'homepageinfo.txt.homepage_name': '홈페이지명',
    'homepageinfo.txt.business_tel': '병원 대표 전화(상담)',
    'homepageinfo.txt.working_time': '진료시간',
    'homepageinfo.txt.closeconfirm1': '필수정보 미입력시</br>홈페이지 서비스를 이용할 수 없습니다.</br>닫으시겠습니까?',
    'homepageinfo.txt.closeconfirm2': '수정한 내용이 반영되지 않습니다.</br>닫으시겠습니까?',
    'homepageinfo.txt.closefirst1': '홈페이지 주소(URL) 생성 중입니다.',
    'homepageinfo.txt.closefirst2': '<p class="mt-2 text-center font-size-14 fw-normal">완료되기까지 약간의 시간이 소요되오니,</br>그동안 홈페이지 편집 작업을 자유롭게 진행해주세요.</p>',
}