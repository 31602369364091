import { getUserInfo } from '@/api/user-api';
import router from "../router";
const jwt = 'fitness-token';
const userState = 'fitness-userState';
const state = {
  isLogin: localStorage.getItem(jwt) ? true : false,
  jwt: null,
  userState: null,
}

const toLogin = () => {
  router.replace({
    name: "Login",
    query: {
      redirect: router.currentRoute.fullPath,
      mode: 'forcelogout'
    },
  });
};

// getters
const getters = {
  isLogin: (state) => {
    return state.isLogin;
  },
  getJWT: (state) => {
    const cachedJWT = state.jwt || localStorage.getItem(jwt);
    if (!cachedJWT) return null;
    return cachedJWT;
  },
  getMe: (state) => {
    const user = state.userState || localStorage.getItem(userState);
    if (!user) return null;
    return JSON.parse(user);
  },
}

// actions
const actions = {
  setUserState: async ({ commit }) => {
    let data = {};
    const rst = await getUserInfo();
    if (rst.result) {
      data = rst.data.userinfo;
    } else {
      return toLogin();
    }
    return commit('setUserState', JSON.stringify(data));
  },
  async logout({ commit }) {
    return commit('logout');
  }, 
}

// mutations
const mutations = {
  setJWTToken: (state, payload) => {
    state.jwt = payload;
    state.isLogin = true;
    localStorage.setItem(jwt, state.jwt);
  },
  clearJWTToken: (state) => {
    state.jwt = '';
    state.isLogin = false;
    localStorage.removeItem(jwt);
  },
  setUserState: (state, payload) => {
    state.userState = payload;
    localStorage.setItem(userState, state.userState);
  },
  clearUserState: (state) => {
    state.userState = '';
    localStorage.removeItem(userState);
  },
  logout: (state) => {
    state.jwt = '';
    state.userState = '';
    state.isLogin = false;
    localStorage.removeItem(jwt);
    localStorage.removeItem(userState);
  }
}
export default { namespaced: true, state, getters, actions, mutations };
